import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
  Segment,
  Header,
  Button,
  Responsive,
} from '@jvs-group/jvs-mairistem-composants';

// import pdf from '../../public/assets/aide.pdf';

import pdf from '../assets/aide.pdf';

/**
 * Authentification.
 *
 * @param {*} props
 */
const Choix = (props) => {
  const handleSelect = (value) => {
    _.invoke(props, 'onSelect', value);
  };

  const handleClickHelp = (e) => {
    e.stopPropagation();

    window.open(pdf, '_blank');
  };

  return (
    <Segment.Group>
      <Segment basic style={{ paddingBottom: '3rem' }}>
        <Header
          textAlign="center"
          style={{
            alignSelf: 'center',
            color: 'rgb(44, 113, 177)',
            fontSize: 30,
            fontFamily: 'Roboto Condensed, sans-serif',
          }}
        >
          Sélectionner le cas qui concerne votre demande
        </Header>
      </Segment>
      <>
        <Responsive as={React.Fragment} minWidth={1000}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Segment
              floated="left"
              style={{
                border: 'none', boxShadow: 'none', padding: 0, marginBottom: 0, marginTop: 0,
              }}
            >
              <Button
                basic
                onClick={() => handleSelect(false)}
                style={{
                  width: 400,
                  height: 300,
                  margin: '0 auto',
                  padding: 40,
                  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
                  background: 'white',
                }}
              >
                <Header as="h4">
                  Déclarer un nouveau bénéficiaire signataire de flux Hélios
                </Header>
              </Button>
            </Segment>
            <Segment
              raised
              floated="left"
              style={{
                border: 'none',
                height: '50%',
                display: 'flex',
                alignItems: 'center',
                margin: 0,
                boxShadow: 'none',
              }}
            >
              <Header as="h2">OU</Header>
            </Segment>
            <Segment
              floated="right"
              style={{
                border: 'none', boxShadow: 'none', padding: 0, marginTop: 0, marginBottom: 0,
              }}
            >
              <Button
                basic
                onClick={() => handleSelect(true)}
                style={{
                  width: 400,
                  height: 300,
                  margin: '0 auto',
                  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
                  background: 'white',
                }}
              >
                <Header as="h4" style={{ margin: 0 }}>
                  Déclarer un nouveau bénéficiaire signataire de flux Hélios et télé-transmetteur
                  de flux Actes ou uniquement télé-transmetteur de flux Actes
                </Header>
                <Segment
                  basic
                  style={{
                    position: 'absolute',
                    bottom: 25,
                    left: 25,
                  }}
                >
                  <Header as="h4" style={{ marginBottom: 0 }}>
                    Un certificat vous sera demandé.
                  </Header>
                  <Header as="h4" style={{ margin: 0 }}>
                                    <a href="#" onClick={handleClickHelp}>Consulter la notice d&apos;export de votre certificat</a> {/* eslint-disable-line */}
                  </Header>
                </Segment>

              </Button>
            </Segment>
          </div>
        </Responsive>
        <Responsive as={React.Fragment} minWidth={0} maxWidth={999}>
          <Segment style={{ border: 'none' }}>
            <Button
              basic
              onClick={() => handleSelect(false)}
              style={{
                width: 400,
                height: 150,
                margin: '0 auto',
                padding: 40,
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
                background: 'white',
              }}
            >
              <Header as="h4">
                Déclarer un nouveau bénéficiaire signataire de flux Hélios
              </Header>
            </Button>
          </Segment>
          <Segment
            style={{
              border: 'none',
            }}
          >
            <Header as="h2">OU</Header>
          </Segment>
          <Segment style={{ border: 'none' }}>
            <Button
              basic
              onClick={() => handleSelect(true)}
              style={{
                width: 400,
                height: 200,
                margin: '0 auto',
                padding: 40,
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
                background: 'white',
              }}
            >
              <Header as="h4">
                Déclarer un nouveau bénéficiaire signataire de
                flux Hélios et télé-transmetteur de flux Actes
              </Header>
              <Header as="h4" style={{ marginBottom: 0 }}>
                Un certificat vous sera demandé.
              </Header>
              <Header as="h4" style={{ margin: 0 }}>
                                <a href="#" onClick={handleClickHelp}>Consulter la notice d&apos;export de votre certificat</a> {/* eslint-disable-line */}
              </Header>
            </Button>
          </Segment>
        </Responsive>
      </>
    </Segment.Group>
  );
};

Choix.propTypes = {
  /** On select choice function. */
    onSelect: PropTypes.func, //eslint-disable-line 
};

export default Choix;
