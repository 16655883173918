import _ from 'lodash';
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  Segment,
  Header,
  Form,
  Button,
  Popup,
  Icon,
  Image,
} from '@jvs-group/jvs-mairistem-composants';

import identifiant from '../assets/images/identifiant.png';

/**
 * Authentification.
 *
 * @param {*} props
 */
const Authentification = (props) => {
  const { loading } = props;

  const [
    numeroClient,
    setNumeroClient,
  ] = useState('');

  const [
    codePostal,
    setCodePostal,
  ] = useState('');

  const [
    cleControle,
    setCleControle,
  ] = useState('');

  const handleChangeNumero = (e, { value }) => {
    if ((/^\d+$/.test(value) && _.size(value) <= 5) || value === '') {
      setNumeroClient(value);
    }
  };

  const handleChangeCodePostal = (e, { value }) => {
    if ((/^\d+$/.test(value) && _.size(value) <= 5) || value === '') {
      setCodePostal(value);
    }
  };

  const handleChangeCleControle = (e, { value }) => {
    if ((/^\d+$/.test(value) && _.size(value) <= 5) || value === '') {
      setCleControle(value);
    }
  };

  const handleSubmit = () => _.invoke(props, 'onSubmit', numeroClient, codePostal, cleControle);

  return (
    <Segment.Group>
      <Segment basic style={{ paddingBottom: '3rem' }}>
        <Header
          textAlign="center"
          style={{
            alignSelf: 'center',
            color: 'rgb(44, 113, 177)',
            fontSize: 30,
            fontFamily: 'Roboto Condensed, sans-serif',
          }}
        >
          iXChange2 - CHANGEMENT BENEFICIAIRE COMPTE TDT/PARAPHEUR
        </Header>
        <div style={{
          width: '80%',
          marginBottom: '1%',
          margin: 'auto',
        }}
        >
          <Header as="h4">
            Vous pouvez utiliser ce formulaire pour les cas suivants :
          </Header>
          <Header as="h4" style={{ marginBottom: 0 }}>
            - Vous devez déclarer un nouveau bénéficiaire signataire de flux Hélios
          </Header>
          <Header as="h4" style={{ margin: 0 }}>
            - Vous devez déclarer un nouveau bénéficiaire signataire de flux Hélios et
            télé-transmetteur de flux actes ou uniquement télé-transmetteur de flux Actes
          </Header>
          <Header color="red" as="h4">
            Important: L&apos;étape de création des comptes iXChange2 ne peut être
            réalisée que si vous disposez de votre nouveau certificat
          </Header>
        </div>
      </Segment>
      <Segment
        basic
        textAlign="center"
        style={{
          width: 350,
          margin: '40px auto',
          padding: 40,
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
          background: 'white',
        }}
      >
        <Header>Authentification</Header>
        <Form onSubmit={handleSubmit}>
          <Form.Input
            label="Identifiant (N° de client attribué par JVS)"
            value={numeroClient}
            onChange={handleChangeNumero}
            required
            action={(
              <Popup
                position="bottom center"
                flowing
                on="hover"
                size="large"
                trigger={
                  <Button type="button" tabIndex={-1} basic icon="info circle" style={{ width: 35 }} />
                                }
                content={
                  <Image size="big" src={identifiant} />
                                }
              />
                    )}
          />
          <Form.Input
            label="Code postal de votre collectivité"
            value={codePostal}
            onChange={handleChangeCodePostal}
            required
          />
          <Form.Input
            label="Clé de contrôle"
            value={cleControle}
            onChange={handleChangeCleControle}
            required
          />
          <Form.Button
            type="submit"
            positive
            loading={loading}
          >
            <Icon name="check" />
            Connexion
          </Form.Button>
        </Form>
      </Segment>
    </Segment.Group>
  );
};

Authentification.propTypes = {
  /** On submit formulaire function. */
  onSubmit: PropTypes.func, //eslint-disable-line 

  /** Loading. */
  loading: PropTypes.bool,
};

Authentification.defaultProps = {
  onSubmit: null,
  loading: false,
};

export default Authentification;
