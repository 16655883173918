import _ from 'lodash';
import React, { useState } from 'react';
import axios from 'axios';

import {
  Grid,
  Button,
  Icon,
  Image,
} from '@jvs-group/jvs-mairistem-composants';
import { Modal } from 'semantic-ui-react';

import {
  SemanticToastContainer,
  toast,
} from 'react-semantic-toasts';

import logo from '../assets/images/logo.svg';
import Formulaire from './Formulaire';
import Authentification from './Authentification';
import Choix from './Choix';
import RGPD from './RGPD';
import Background from './Background';

const url = '/api';

/**
 * Application.
 */
const Application = () => {
  const [authentified, setAuthentified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numeroClient, setNumeroClient] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [city, setCity] = useState('');
  const [messageError, setMessageError] = useState(null);
  const [withCer, setWithCer] = useState(null);
  const [cleControle, setCleControle] = useState('');
  const [rgpd, setRgpd] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [token, setToken] = React.useState();

  const handleAuthentification = (idClient, cp, key) => {
    setLoading(true);
    setNumeroClient(idClient);
    setCleControle(key);
    setCodePostal(cp);

    axios.post(url, {
      numeroClient: idClient,
      codePostal: cp,
      cleControle: key,
    }).then((response) => {
      setLoading(false);
      if (response.status && response.status === 200) {
        setCity(response?.data?.city);
        setToken(response?.data?.token);
        setAuthentified(true);

        toast({
          title: 'Authentification réussie',
          description: '',
          type: 'success',
          time: 5000,
        });
      }
    }).catch((e) => {
      setLoading(false);

      const message = !_.isNil(e)
            && !_.isNil(e.response)
            && !_.isNil(e.response.data)
            && !_.isNil(e.response.data.message)
        ? e.response.data.message
        : 'Authentification échoué';

      toast({
        title: 'Authentification échouée',
        description: <p>{message}</p>,
        type: 'error',
        time: 5000,
      });
    });
  };

  const handleSubmitFormulaire = (
    nom,
    prenom,
    nomSortant,
    prenomSortant,
    siren,
    mail,
    lastId,
    qualite,
    file,
    fileName,
  ) => {
    setLoading(true);

    const parameters = {
      nom,
      prenom,
      nomSortant,
      prenomSortant,
      codePostal,
      siren,
      mail,
      city,
      lastId,
      numeroClient,
      cleControle,
      qualite,
      ...withCer
        ? {
          file,
          fileName,
        }
        : null,
    };

    axios.post(`${url}/pass`, parameters, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      setMessageError(null);

      if (response.status && response.status === 200) {
        setAuthentified(false);
        setLoading(false);
        setWithCer(null);
        setModalOpen(true);
      }
    }).catch((e) => {
      setLoading(false);

      const title = withCer ? 'certificat' : 'formulaire';

      const message = !_.isNil(e)
            && !_.isNil(e.response)
            && !_.isNil(e.response.data)
            && !_.isNil(e.response.data.message)
        ? e.response.data.message
        : `L'envoi du ${title} a échoué.`;

      setMessageError(message);
    });
  };

  const handleSelectChoice = (cer) => setWithCer(cer);

  const handleBack = () => setWithCer(null);

  const handleAccept = () => setRgpd(true);

  const handleModalClose = () => setModalOpen(false);

  const handleRefuse = () => { window.location.href = 'https://jvs-mairistem.fr'; };

  return (
    <>
      <SemanticToastContainer />
      {!rgpd && <RGPD onAccept={handleAccept} onRefuse={handleRefuse} /> }
      <Background />
      <Modal open={modalOpen} onClose={handleModalClose} size="mini">
        <Modal.Header>
          L&apos;envoi du
          {withCer ? ' certificat' : ' formulaire'}
          {' '}
          a réussi
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>Votre demande sera traitée sous 48 heures.</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            positive
            onClick={handleModalClose}
          >
            <Icon name="checkmark" />
            {' '}
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          margin: 0,
        }}
      >
        <Grid.Row centered style={{ padding: 0 }}>
          {/* <Header /> */}
          <Image
            src={logo}
            style={{ width: 300 }}
            size="large"
            fluid
          />
        </Grid.Row>
        <Grid.Row
          stretched
          centered
          style={{
            flex: 1, padding: 0,
          }}
        >
          {authentified
            ? !_.isNil(withCer)
              ? (
                <Formulaire
                  onSubmit={handleSubmitFormulaire}
                  onBack={handleBack}
                  loading={loading}
                  messageError={messageError}
                  certificat={withCer}
                />
              )
              : (<Choix onSelect={handleSelectChoice} />)
            : (
              <Authentification
                onSubmit={handleAuthentification}
                loading={loading}
              />
            )}
        </Grid.Row>
        {/* <Grid.Row centered style={{ padding: 0 }}>
          <Footer />
        </Grid.Row> */}
      </Grid>
    </>
  );
};

export default Application;
