import _ from 'lodash';
import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {
  Form,
  Segment,
  Header,
  Icon,
  Ref,
  Popup,
  Button,
  Grid,
  Message,
  Divider,
  Dropzone,
} from '@jvs-group/jvs-mairistem-composants';
import { toast } from 'react-semantic-toasts';

import pdf from '../assets/aide.pdf';

/**
 * Formulaire.
 *
 * @param {*} props
 */
const Formulaire = (props) => {
  const {
    loading,
    messageError,
    certificat,
  } = props;

  const [nom, setNom] = useState('');
  const [mail, setMail] = useState('');
  const [lastId, setLastId] = useState('');
  const [prenom, setPrenom] = useState('');
  const [siren, setSiren] = useState(['', '', '', '', '']);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [nbInputSuppSiren, setNbInputSuppSiren] = useState(0);
  const [previousRejected, setPreviousRejected] = useState([]);
  const [qualite, setQualite] = useState('');
  const [qualiteOther, setQualiteOther] = useState('');
  const [error, setError] = useState(null);
  const [nomSortant, setNomSortant] = useState('');
  const [prenomSortant, setPrenomSortant] = useState('');

  const handleChangeNom = (e, { value }) => setNom(value);

  const handleChangePrenom = (e, { value }) => setPrenom(value);

  const handleChangeNomSortant = (e, { value }) => setNomSortant(value);

  const handleChangePrenomSortant = (e, { value }) => setPrenomSortant(value);

  const handleChangeMail = (e, { value }) => setMail(value);

  const handleChangeLastId = (e, { value }) => setLastId(value);

  const handleBack = () => _.invoke(props, 'onBack');

  const handleChangeSiren = ({ value }, index) => {
    if (/^\d{0,3}[ ]?\d{0,3}[ ]?\d{0,3}$/.test(value)) {
      const tmp = siren;

      tmp[index] = value;

      setSiren([...tmp]);
    }
  };

  const handleAddSiren = () => {
    if (nbInputSuppSiren < 4) setNbInputSuppSiren(nbInputSuppSiren + 1);
  };

  const handleChangeQualite = (e, { value }) => setQualite(value);

  const handleChangeNameQualite = (e, { value }) => { setQualiteOther(value); };

  const handleSubmit = () => {
    let description = '';

    if (certificat && (_.isNil(file) || _.isEmpty(fileName))) {
      description = 'Aucun certificat renseigné';
    } else if (_.isEmpty(nom)) {
      description = "Le nom n'est pas renseigné";
    } else if (_.isEmpty(prenom)) {
      description = "Le prénom n'est pas renseigné";
    } else if (_.isEmpty(siren[0]) || _.isNil(siren[0])) {
      description = "Le SIREN obligatoire n'est pas renseigné";
    } else if (_.size(_.replace(siren[0], / /g, '')) < 9) {
      description = 'Le SIREN doit comporter 9 chiffres';
    } else if (_.isEmpty(mail)) {
      description = "L'adresse mail du bénéficiaire n'est pas renseignée";
    } else if (_.isEmpty(qualite)) {
      description = 'La qualité doit être renseignée';
    } else if (_.isEmpty(nomSortant) && _.isEmpty(prenomSortant) && _.isEmpty(lastId)) {
      description = "Les informations de l'ancien bénéficiaire ne sont pas renseignées. Il faut renseigner soit son nom et son prénom soit son identifiant";
    } else if (_.isEmpty(lastId) && (_.isEmpty(nomSortant) || _.isEmpty(prenomSortant))) {
      description = "Les informations de l'ancien bénéficiaire ne sont pas renseignées. ";
    } else if (qualite === 'autre' && _.isEmpty(qualiteOther)) {
      description = 'La qualité doit être précisée';
    } else if (_.isEqual(mail, lastId)) {
      description = "L'adresse mail nominative du nouveau bénéficiaire et l'identifiant de connexion de l'ancien bénéficiaire doivent être différent";
    }

    if (description === '') {
      _.invoke(props, 'onSubmit', nom, prenom, nomSortant, prenomSortant, siren, mail, lastId, qualite === 'autre' ? qualiteOther : qualite, file, fileName);
    } else {
      setError(description);
    }
  };

  const options = [
    {
      key: 'maire', value: 'maire', text: 'Maire',
    },
    {
      key: 'president', value: 'president', text: 'Président',
    },
    {
      key: 'maire et president', value: 'maire et president', text: 'Maire et Président',
    },
    {
      key: 'autre', value: 'autre', text: 'Autre',
    },
  ];

  return (
    <Segment.Group>
      {!_.isNil(certificat) && (
        <Segment basic>
          <Header
            textAlign="center"
            style={{
              alignSelf: 'center',
              color: 'rgb(44, 113, 177)',
              fontSize: 30,
              fontFamily: 'Roboto Condensed, sans-serif',
              maxWidth: 1300,
            }}
          >
            { certificat
              ? 'Déclaration d\'un nouveau bénéficiaire de flux Hélios et télé-transmetteur de flux Actes ou uniquement télé-transmetteur de flux Actes'
              : 'Déclaration d\'un nouveau bénéficiaire signataire de flux Hélios'}
          </Header>
        </Segment>
      )}
      <Segment
        textAlign="left"
        style={{
          width: 700,
          margin: '40px auto',
          border: 'none',
          padding: 40,
          background: 'white',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
        }}
      >
        <Header><b>Formulaire</b></Header>
        <Form onSubmit={handleSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              label="Nom du nouveau bénéficiaire"
              value={nom}
              onChange={handleChangeNom}
              required
            />
            <Form.Input
              label="Prénom du nouveau bénéficiaire"
              value={prenom}
              onChange={handleChangePrenom}
              required
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Dropdown
              options={options}
              label="Fonction du nouveau bénéficiaire"
              value={qualite}
              onChange={handleChangeQualite}
              required
              selection
              fluid
            />
            {qualite !== '' && qualite !== 'maire' && qualite !== 'president' && qualite !== 'maire et president' && (
            <Form.Input
              label="Précisez le mandat du nouveau bénéficiaire"
              value={qualiteOther}
              onChange={handleChangeNameQualite}
              required
            />
            )}
          </Form.Group>
          <Form.Input
            type="email"
            label="Adresse mail nominative du nouveau bénéficiaire"
            value={mail}
            onChange={handleChangeMail}
            required
            action={(
              <Popup
                position="bottom center"
                flowing
                on="hover"
                trigger={
                  <Button type="button" tabIndex={-1} basic icon="info circle" style={{ width: 35 }} />
                                }
                content="Cette adresse mail servira d'identifiant de connexion au nouveau bénéficiaire pour le parapheur"
              />
                        )}
          />
          <Form.Group grouped>
            <Form.Input
              label="SIREN"
              value={siren[0]}
              onChange={(e, data) => handleChangeSiren(data, 0)}
              required
              action
            >
              <input style={{ width: '442px' }} />
              {nbInputSuppSiren < 4 && (
                <Popup
                  position="bottom center"
                  trigger={<Button type="button" icon="add" basic onClick={handleAddSiren} />}
                  content="Ajouter un SIREN supplémentaire"
                />
              )}
              <Popup
                position="bottom center"
                flowing
                on="hover"
                trigger={<Button type="button" icon="info circle" basic />}
                content="Vous pouvez ajouter jusqu'à 4 SIREN supplémentaires"
              />
            </Form.Input>
            {_.times(nbInputSuppSiren, (item) => (
              <Form.Input
                value={siren[item + 1]}
                onChange={(e, data) => handleChangeSiren(data, item + 1)}
              />
            ))}
          </Form.Group>
          <Segment>
            <Form.Group widths="equal">
              <Form.Input
                label={'Nom de l\'ancien bénéficiaire'}
                value={nomSortant}
                onChange={handleChangeNomSortant}
                {...!_.isEmpty(lastId) ? {} : { required: true }}
              />
              <Form.Input
                label={'Prénom de l\'ancien bénéficiaire'}
                value={prenomSortant}
                onChange={handleChangePrenomSortant}
                {...!_.isEmpty(lastId) ? {} : { required: true }}
              />
            </Form.Group>
            <Divider horizontal>Et / Ou</Divider>
            <Form.Input
              label={'Identifiant de connexion au parapheur de l\'ancien bénéficiaire (Nom utilisateur)'}
              value={lastId}
              onChange={handleChangeLastId}
              {...!_.isEmpty(prenomSortant) && !_.isEmpty(nomSortant) ? {} : { required: true }}
            />
          </Segment>
          {certificat
                && (
                <Dropzone
                  accept=".cer"
                  multiple={false}
                  onDrop={(acceptedFiles) => {
                    _.forEach(acceptedFiles, (f) => {
                      const reader = new FileReader();

                      reader.readAsText(f);
                      reader.onload = () => {
                        const rawLog = reader.result;

                        setFile(rawLog);
                      };

                      setFileName(f.name);
                    });
                  }}
                >
                    {({
                      getRootProps, getInputProps, rejectedFiles,
                    }) => {
                      const {
                        ref,
                        ...rootProps
                      } = getRootProps();

                      setPreviousRejected(rejectedFiles);

                      if (
                        !_.isEqual(rejectedFiles, previousRejected)
                        && !_.isEmpty(rejectedFiles)) {
                        toast({
                          title: 'Mauvais format de fichier',
                          description: 'Seuls les fichiers .cer sont acceptés',
                          type: 'warning',
                          time: 5000,
                        });
                      }

                      return (
                        <Form.Field required>
                                    <label>Certificat</label> {/* eslint-disable-line */}
                          <Ref innerRef={ref}>
                            <Segment
                              style={{ marginTop: 0 }}
                              {...rootProps}
                            >
                              <input {...getInputProps()} />
                              <p>
                                Glisser/déposer un fichier ou cliquer pour ouvrir l&apos;explorateur
                              </p>
                            </Segment>
                          </Ref>
                        </Form.Field>
                      );
                    }}
                </Dropzone>
                )}
          {!_.isNil(file) && !_.isEmpty(fileName) && (
            <p>
              <Icon name="check" color="green" />
              {fileName}
            </p>
          )}
          <Grid>
            <Grid.Row>
              <Grid.Column width={5}>
                <Form.Button
                  type="button"
                  basic
                  floated="left"
                  onClick={handleBack}
                >
                  <Icon name="angle left" />
                  Retour
                </Form.Button>
              </Grid.Column>
              <Grid.Column width={6}>
                { certificat && (
                <Form.Button
                  type="button"
                  color="blue"
                  basic
                  onClick={() => { window.open(pdf, '_blank'); }}
                >
                  <Icon name="question" />
                  Consulter l&apos;aide&nbsp;&nbsp;&nbsp;
                </Form.Button>
                )}
              </Grid.Column>
              <Grid.Column width={5}>
                <Form.Button
                  type="submit"
                  positive
                  loading={loading}
                  floated="right"
                >
                  <Icon name="check" />
                  Envoyer
                </Form.Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        {(!_.isNil(messageError) || !_.isNil(error)) && (
        <Message error>
          <Message.Header>L&apos;envoi du certificat a échoué.</Message.Header>
          <p>{!_.isNil(error) ? error : messageError}</p>
        </Message>
        )}
      </Segment>
    </Segment.Group>
  );
};

/** Proptypes. */
Formulaire.propTypes = {
  /** On submit formulaire function. */
  onSubmit: PropTypes.func,

  /** Loading. */
  loading: PropTypes.bool,

  /** Message error. */
  messageError: PropTypes.string,

  /** If certificat is displayed. */
  certificat: PropTypes.func,
};

Formulaire.defaultProps = {
  onSubmit: null,
  loading: false,
  messageError: '',
  certificat: null,
};

export default Formulaire;
