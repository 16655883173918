import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Icon,
} from '@jvs-group/jvs-mairistem-composants';

import { Modal } from 'semantic-ui-react';

const RGPD = (props) => {
  const {
    onAccept,
    onRefuse,
  } = props;

  return (
    <Modal open>
      <Modal.Header style={{ backgroundColor: 'inherit', color: 'inherit', padding: '1.25rem 1.50rem' }}>Réglement général sur la protection des données</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            En utilisant ce formulaire, vous autorisez
            {' '}
            <b>le Groupe JVS et ses filiales</b>
            {' '}
            à utiliser les données à caractère personnel collectées au travers de ce formulaire
            {' '}
            <b>dans le but</b>
            {' '}
            de satisfaire à l’exécution du traitement de création de
            comptes iXChange2 pour vos nouveaux bénéficiaires.
          </p>
          <p>
            Les données collectées via ce formulaire seront supprimées à l’issue de
            leur traitement.
          </p>
          <p>
            <b>JVS</b>
            {' '}
            s’engage à gérer les données selon les bonnes pratiques et recommandations liées au RGPD
            comme indiqué dans notre politique de
            confidentialité consultable à l’adresse ci-dessous :
          </p>
          <p>
            <a href="https://en.calameo.com/read/00027442620a607eafa8c?authid=IDXXYkJ5jD4c" rel="noopener noreferrer" target="_blank">
              Politique de confidentialité
            </a>
          </p>
          <p>
            Vous devez accepter ce réglement pour pouvoir avoir accès au site web.
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          basic
          onClick={onRefuse}
        >
          <Icon name="cancel" />
          Je refuse
        </Button>
        <Button
          positive
          onClick={onAccept}
        >
          <Icon name="checkmark" />
          {'J\'accepte'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

RGPD.propTypes = {
  onAccept: PropTypes.func,
  onRefuse: PropTypes.func,
};

RGPD.defaultProps = {
  onAccept: null,
  onRefuse: null,
};

export default RGPD;
